import React from 'react';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setActivePanel, setActiveModal, setCurrentWomanID } from "../store/mainReducer"
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import bridge from '@vkontakte/vk-bridge';

import 'swiper/css';

import PercentsCircleBar from '../components/PercentsCircleBar'

const About = () => {
    const dispatch = useDispatch()

    const currentWomanID = useSelector(state => state.main.currentWomanID)
    const launch_params = useSelector(state => state.main.launch_params)
    const platform = launch_params.vk_platform
    const women = useSelector(state => state.main.women)
    const currentWoman = women.find(woman=>woman.id == currentWomanID)
    const currentWomanDate = currentWoman.date
    const currentWomanName = currentWoman.name
    const currentWomanAdress = currentWoman.adress
    const currentWomanAchievement = currentWoman.achievement
    const currentWomanDescription = currentWoman.description
    const aboutPageWrapper = useRef()

    useEffect(()=>{
        const windowActualHeight = aboutPageWrapper.current.clientHeight

        bridge.send('VKWebAppResizeWindow', {
            height: windowActualHeight + 5
        });
    }, [])

    return (
        <div className="container about-page" ref={aboutPageWrapper}>
            <p className="about-page__caption">
                Проект <br/>«Женских рук дело»
            </p>
            <p className="about-page__description" dangerouslySetInnerHTML={{__html:
                    'Всего 2% памятников в&nbsp;России посвящены женщинам.<br>ВКонтакте запустила социальный проект о&nbsp;выдающихся женщинах в&nbsp;науке, режиссуре, медицине и&nbsp;других областях. В&nbsp;их честь установят инсталляции с&nbsp;3D-голограммами.<br>Открытым голосованием пользователи выбрали героиню первого памятника — Магдалину Петровну Покровскую. '
                }}>
                </p>
            <div className="about-woman about-page__about-woman">
                <div className="about-woman__pic-wrapper">
                    <img className="about-woman__pic" src={`assets/images/about-women/about-woman${currentWomanID}.jpg`} width="374" height="377" alt="" />
                </div>
                <div className="about-woman__content">
                    <p className="about-woman__title" dangerouslySetInnerHTML={{__html:currentWomanName}}></p>
                    <p className="about-woman__date" dangerouslySetInnerHTML={{__html: currentWomanDate}}></p>
                    <p className="about-woman__achievement" dangerouslySetInnerHTML={{__html: currentWomanAchievement}}></p>
                    {/* <button className="about-woman__more-button sub-button" onClick={()=>dispatch(setActiveModal('info'))}>
                        Подробнее
                    </button> */}
                    <p className="about-woman__description" dangerouslySetInnerHTML={{__html: currentWomanDescription}}></p>
                </div>
            </div>
            <div className="woman-location about-page__woman-location">
                <p className="woman-location__caption">
                    Где расположен памятник?
                </p>
                {/* <img className="woman-location__pic" src={`assets/images/about-women/location-woman${currentWomanID}.png`} width="282" height="173" alt="" /> */}
                <p className="woman-location__adress" dangerouslySetInnerHTML={{__html: currentWomanAdress}}></p>
            </div>
            <div className="about-nav">
                <button className="results-page__more-button" onClick={()=>dispatch(setActivePanel('main'))}>О других героинях</button>
                <button className="icon-button" onClick={()=>{dispatch(setActiveModal('share'))}}>
                    <img src="assets/images/share-icon.svg" alt="" />
                </button>
            </div>
        </div>
    );
}

export default About;

