import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActiveModal, switchModalActive} from "../store/mainReducer"

const Overlay = (props) => {
    const dispatch = useDispatch()
    const activeModal = useSelector(state => state.main.activeModal)
    

    return (
        activeModal !== null &&
        <div className="overlay" onClick={()=>{dispatch(setActiveModal(null))}}>
            {props.children}
        </div>
    );
}

export default Overlay;

