import React from 'react';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setActivePanel, setActiveModal, setCurrentWomanID } from "../store/mainReducer"
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

import PercentsCircleBar from '../components/PercentsCircleBar'

const Results = () => {
    const dispatch = useDispatch()

    useEffect(() => {

    }, []);

    const women = useSelector(state => state.main.women)
    const percentages = useSelector(state => state.main.percentages)
    const platform = useSelector(state => state.main.launch_params.vk_platform)
    const percentagesValue = Object.values(percentages)
    let maxValue = 0
    percentagesValue.map(value=>{
        let currentValue = +value.replace('%', '')
        currentValue > maxValue && (
            maxValue = currentValue
        )
    })

    const winnerWomanID = Object.keys(percentages).find(key => percentages[key] === `${maxValue}%`);
    const winnerWoman = women.find(woman=>woman.id === +winnerWomanID)

    const womenSorted = []

    let percentagesSorted = []

    for (let percent in percentages) {
        percentagesSorted.push([percent, percentages[percent]]);
    }

    percentagesSorted.sort((a,b)=>{
        return  +b[1].replace('%', '') - +a[1].replace('%', '')
    })

    percentagesSorted.map((percent)=>{
        womenSorted.push(women[percent[0]-1])
    })

    return (
        <div className="container results-page">
            <div className="results-block">
                <p className="results-block__caption">
                    Голосование завершено
                </p>
                <div className="results-block__woman-block">
                    <img className="results-block__woman" src={`assets/images/woman-results-pic.png`} width="307" height="299" alt="" />
                    <div className="results-block__pad">
                        <img src="assets/images/woman-light-pad.png" width="350" height="270" alt="" />
                    </div>
                    <p className="results-block__winner-label">
                        Победитель!
                    </p>
                </div>
                <p className="results-block__name" dangerouslySetInnerHTML={{__html: winnerWoman.name}}></p>
                <p className="results-block__date" dangerouslySetInnerHTML={{__html: winnerWoman.date}}></p>
                <p className="results-block__achievement" dangerouslySetInnerHTML={{__html: winnerWoman.achievement}}></p>
            </div>
            {/* <div className="participants-slider">
                { platform === "desktop_web" ?
                <p className="participants-slider__caption-desktop">
                    Участники голосования
                </p>
                :
                <p className="participants-slider__caption">
                    Участники:
                </p>
                }
                { platform === "desktop_web" ?
                <div className="articipants-list">
                    {
                        womenSorted.map((woman)=>{
                            return(
                                <div className="participant-slide" key={woman.id}>
                                    <div className="participant-slide__image">
                                        <img className="participant-slide__photo" src={`assets/images/women-pics/woman-in-circle${woman.id}.png`} width="100" height="100" alt="" />
                                        <p className="participant-slide__percent">
                                            <PercentsCircleBar percentValue={percentages[woman.id]} width={30} height={30}/>
                                        </p>
                                    </div>
                                    <p className="participant-slide__name" dangerouslySetInnerHTML={{__html: woman.name}}></p>
                                </div>
                            )
                        })
                    }
                </div>
                :
                <div className="participants-slider__rail">
                    <Swiper
                        modules={[Pagination]}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        onSlideChangeTransitionEnd={()=>{
                            dispatch(setCurrentWomanID(document.querySelector('.swiper-slide-active .participant-slide').getAttribute('data-id')))
                        }}
                    >
                        {
                            womenSorted.map((woman)=>{
                                return(
                                    <SwiperSlide key={woman.id}>
                                        <div className="participant-slide" data-id={woman.id}>
                                            <div className="participant-slide__image">
                                                <img className="participant-slide__photo" src={`assets/images/women-pics/woman-in-circle${woman.id}.png`} width="100" height="100" alt="" />
                                                <p className="participant-slide__percent">
                                                    <PercentsCircleBar percentValue={percentages[woman.id]} width={30} height={30}/>
                                                </p>
                                            </div>
                                            <p className="participant-slide__name" dangerouslySetInnerHTML={{__html: woman.name}}></p>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
                }

            </div> */}
            <div className="results-nav">
                <p className="results-nav__info">
                    Вскоре мы установим ей первый голографический памятник. Следите за датой и местом в этом приложении.
                </p>
                <div className="main-page-nav results-page__main-page-nav">
                    <button className="icon-button" onClick={()=>dispatch(setActivePanel('status'))}>
                        <img src="assets/images/smile-icon.svg" alt="" />
                    </button>
                    {/* <button className="results-page__more-button" onClick={()=>dispatch(setActiveModal('info'))}>
                        Подробнее
                    </button> */}
                    <button className="icon-button" onClick={()=>{dispatch(setActiveModal('share'))}}>
                        <img src="assets/images/share-icon.svg" alt="" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Results;

