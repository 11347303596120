import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setActivePanel, setActiveModal } from "../store/mainReducer"
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

import Button from '../components/Button';
import { useState } from 'react';
import { sendStatistics } from '../functions';

const Start = () => {
    const dispatch = useDispatch()
    const [sliderActiveIndex, setSliderActiveIndex] = useState(0)
    const [swiperSlider, setSwiperSlider] = useState({});
    const platform = useSelector(state => state.main.launch_params.vk_platform)
    const isUserNew = useSelector(state => state.main.isUserNew)
    const statusApp = useSelector(state => state.main.statusApp)

    useEffect(() => {
        statusApp === 'results' ?
        dispatch(setActivePanel('results')) :
        !isUserNew && dispatch(setActivePanel('main'))
    }, []);

    return (
        <div className={`container start-page ${sliderActiveIndex === 2 ? 'start-page_background_translated' : null}`}>
            <div className="start-images">
                {
                    sliderActiveIndex !== 2 ?
                    <div className={`start-images__first ${sliderActiveIndex === 1 ? 'start-images__first_type_glowing' : null}`}>
                        {
                            platform === 'desktop_web' ?
                            <img className="start-images__woman-figure-desktop" src="assets/images/slider1-desktop-pic.png" alt="" />
                            :
                            <img className="start-images__woman-figure" src="assets/images/slider1-pic.png" alt="" />
                        }

                    </div>
                    :
                    <div className="start-images__final">
                    </div>
                }
            </div>
            <div className="start-slider start-page__start-slider">
                <Swiper
                    // install Swiper modules
                    modules={[Pagination]}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    onSlideChange={(SwiperSlide) => setSliderActiveIndex(SwiperSlide.activeIndex)}
                    onInit={(event) => {
                        setSwiperSlider(event)
                    }}
                >
                    <SwiperSlide>
                        <div className="start-slide">
                            <p className="start-slide__caption start-slide__caption_type_margined">В России всего <span>2,5%</span>&nbsp;памятников <br/><span>посвящены женщинам</span></p>
                            <Button className="start-slide__button" onClick={()=>swiperSlider.slideNext()}>Продолжить</Button>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="start-slide">
                            <p className="start-slide__caption start-slide__caption_type_margined">ВКонтакте установит памятники <span>выдающимся женщинам</span></p>
                            <Button className="start-slide__button" onClick={()=>swiperSlider.slideNext()}>Продолжить</Button>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="start-slide">
                            <p className="start-slide__caption"><span>Решите, кому будет<br/>установлен первый из них</span></p>
                            <p className="start-slide__description">Каждый день вы можете проголосовать за&nbsp;трёх женщин и&nbsp;разблокировать один из&nbsp;трёх уникальных статусов <br/><br/>Итоги: <span>10 апреля</span></p>
                            <Button className="start-slide__button" onClick={()=>{
                                sendStatistics('onboarding_done', {})
                                dispatch(setActivePanel('main'))
                            }}>Голосовать</Button>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>

        </div>
    );
}

export default Start;

