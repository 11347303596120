import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setActivePanel } from "../store/mainReducer"

const Loading = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        const loading = setTimeout(() => {
            dispatch(setActivePanel('main'))
        }, 2500);
        return () => clearTimeout(loading);
    }, []);

    return (
        <div className="container loading-page">
            <a href="#" className="container__logo logo loading-page__logo">
                <img width="143" height="174" src="assets/images/main-logo.svg" alt="logo" />
            </a>
            <div className="loading-block loading-page__loading-block">
                <div className="loading-block__bar"></div>
            </div>
            <img className="loading-page__vk-logo" width="156" height="28" src="assets/images/vk-logo.svg" alt="vk-logo" />
        </div>
    );
}


export default Loading;

