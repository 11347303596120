import React from 'react';

const Button = ({disabled, title, icon, className, onClick, children}) => {
   
    return (
        <button className={`button ${className}`} disabled={disabled} onClick={onClick}>
            {children}
        </button>
    );
}


export default Button;

