import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setActivePanel, setActiveModal, setCountAllVotes } from "../store/mainReducer"
import { sendRetargeting, sendStatistics } from '../functions'
import bridge from '@vkontakte/vk-bridge';
import ServerConnect from '../service'
import { Snackbar } from "@vkontakte/vkui";
import '@vkontakte/vkui/dist/vkui.css';
// import { Icon28CheckCircleFill } from '@vkontakte/icons';
import { Icon16DoneCircle } from '@vkontakte/icons';
const Status = () => {
    const dispatch = useDispatch()
    const launch_params = useSelector(state => state.main.launch_params)
    const countAllVotes = useSelector(state => state.main.countAllVotes)
    const statusApp = useSelector(state => state.main.statusApp)
    const [currentStatusId, setCurrentStatusID] = useState()
    const [snackbar, setSnackbar] = useState(null);

    const openStatusSnackbar = () => {
        if (snackbar) return;
        setSnackbar(
          <Snackbar
            // style={{bottom: 120}}
            onClose={() => setSnackbar(null)}
            before={
                <Icon16DoneCircle width="20" height="20"/>
            }
          >
            Статус установлен
          </Snackbar>
        );
    };

    useEffect(()=>{

        ServerConnect.sendGet('/get_data', {...launch_params}).then(data => {
            dispatch(setCountAllVotes(data.count_all_votes))
        })

        bridge.send("VKWebAppGetAuthToken", {
            "app_id": 51560653,
            "scope": "status"
        }).then(data=>{
            bridge.send('VKWebAppCallAPIMethod', {
                method: 'status.getImage',
                params: {
                    user_id: launch_params.vk_user_id,
                    v: '5.131',
                    access_token: data.access_token
                }})
                .then((data) => {
                    if (data.response) {
                        console.log(data.response.status.id)
                        setCurrentStatusID(data.response.status.id)
                    }
                })
                .catch((error) => {
                    console.log(error);
            });

        })


    }, [])

    const status = (status_id)=> {

        setCurrentStatusID(status_id)

        bridge.send("VKWebAppGetAuthToken", {
            "app_id": 51560653,
            "scope": "status"
        }).then(data=>{
            console.log(data)
            let params = {
                "method": "status.setImage",
                "request_id": launch_params.vk_user_id,
                "params":{"status_id": status_id, "v":"5.131", "access_token": data.access_token}
            }
            bridge.send("VKWebAppCallAPIMethod", params)
            .then(data=>{
                console.log('status_params ', data)
                sendStatistics('status_set', {ID_status: status_id})
                openStatusSnackbar()
            })
            .catch(error=>{
                console.log(error)
            })

        })
    }

    return (
        <div className="container status-page">
            <p className="status-page__caption">
                Установите эксклюзивный<br/>эмодзи-статус
            </p>
            <p className="status-page__description">
                Голосуйте каждый день, <br/>чтобы разблокировать новые статусы
            </p>
            <div className="status-triggers">
                <div className={`status-trigger ${countAllVotes > 0 ? 'status-trigger_unlocked' : ''}`}>
                    <input type="checkbox" name="status" checked={currentStatusId === 914 ? true : false} disabled={countAllVotes > 0 ? false : true} id="status1" onChange={()=>{status(914)}}/>
                    <label htmlFor="status1">
                        <img src="assets/images/status1-pic.png" width="42" height="42" alt="" />
                        <span>Помню</span>
                        <img className="status-trigger__icon" src={`assets/images/${countAllVotes > 0 ? 'arrow' : 'lock'}-icon.svg`} alt="" />
                    </label>
                </div>
                <div className={`status-trigger ${countAllVotes > 1 ? 'status-trigger_unlocked' : ''}`}>
                    <input type="checkbox" name="status" checked={currentStatusId === 915 ? true : false} disabled={countAllVotes > 1 ? false : true} id="status2" onChange={()=>{status(915)}}/>
                    <label htmlFor="status2">
                        <img src="assets/images/status2-pic.png" width="42" height="42" alt="" />
                        <span>Звезда науки</span>
                        <img className="status-trigger__icon" src={`assets/images/${countAllVotes > 1 ? 'arrow' : 'lock'}-icon.svg`} alt="" />
                    </label>
                </div>
                <div className={`status-trigger ${countAllVotes > 2 ? 'status-trigger_unlocked' : ''}`}>
                    <input type="checkbox" name="status" checked={currentStatusId === 916 ? true : false} disabled={countAllVotes > 2 ? false : true} id="status3" onChange={()=>{status(916)}}/>
                    <label htmlFor="status3">
                        <img src="assets/images/status3-pic.png" width="42" height="42" alt="" />
                        <span>Моих рук дело</span>
                        <img className="status-trigger__icon" src={`assets/images/${countAllVotes > 2 ? 'arrow' : 'lock'}-icon.svg`} alt="" />
                    </label>
                </div>
            </div>
            <button className="status-page__back-button" onClick={()=>{
                statusApp === 'results' ?
                dispatch(setActivePanel('results')) :
                dispatch(setActivePanel('main'))
            }}>
                <img src="assets/images/arrow-icon.svg" width="15" height="15" alt="" />
            </button>
            {snackbar}
        </div>
    );
}

export default Status;

