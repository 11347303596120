import bridge from '@vkontakte/vk-bridge';

async function sendStatistics(event, params) {
    bridge.send("VKWebAppSendCustomEvent", {
      "type": "type_action",
      "event": event,
      "json": JSON.stringify({...params}),
    }).then((data) => {
      if (data) {
        console.log(data);
      }
    })
    .catch((error) => {
      console.log(error);
    });

    console.log(event, {...params})
}

async function sendRetargeting(event) {
  console.log(`retargeting event: ${event}` )
  bridge.send('VKWebAppRetargetingPixel', {
    pixel_code: 'VK-RTRG-1690833-eJucY',
    event: JSON.stringify(event)
  })
  .then((data) => {
    if (data.result) {
      console.log(data.result)
    }
  })
  .catch((error) => {
    console.log(error);
  });
}

async function sendConversion(event) {
  console.log(`conversion event: ${event}` )
  bridge.send('VKWebAppConversionHit', {
    pixel_code: 'VK-RTRG-1690833-eJucY',
    conversion_event: JSON.stringify(event),
    conversion_value: 0
  }).then((data) => {
    if (data.result) {
      console.log(data.result)
    }
  }).catch((error) => {
    // Ошибка
    console.log(error);
  });
}

export {sendStatistics, sendRetargeting, sendConversion}