import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setActivePanel, setActiveModal, setCurrentWomanID, setSelected, updateUserCount, setWomanHashUsed } from "../store/mainReducer"
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

import Button from '../components/Button';
import { sendRetargeting } from '../functions'
import { useState } from 'react';
import ServerConnect from '../service';
import { sendStatistics } from '../functions';

import PercentsCircleBar from '../components/PercentsCircleBar';

const Main = () => {
    const dispatch = useDispatch()
    const [sliderActiveIndex, setSliderActiveIndex] = useState(0)
    const [swiperSlider, setSwiperSlider] = useState({})
    const currentWomanID = useSelector(state => state.main.currentWomanID)
    const userCount = useSelector(state => state.main.userCount)
    const percentages = useSelector(state => state.main.percentages)
    const selected = useSelector(state => state.main.selected)
    const platform = useSelector(state => state.main.launch_params.vk_platform)
    const women = useSelector(state => state.main.women)
    const womanHashUsed = useSelector(state => state.main.womanHashUsed)
    const womenSorted = []

    let percentagesSorted = []

    for (let percent in percentages) {
        percentagesSorted.push([percent, percentages[percent]]);
    }

    percentagesSorted.sort((a,b)=>{
        return  +b[1].replace('%', '') - +a[1].replace('%', '')
    })

    percentagesSorted.map((percent)=>{
        womenSorted.push(women[percent[0]-1])
    })

    const voteFor = (id)=> {
        ServerConnect.sendPost('/send_choice', {'woman': id})
        .then(data=>{console.log(data)})
        .catch(error=>console.log(error))
        dispatch(setSelected([...selected, id]))
        dispatch(updateUserCount(userCount-1))
        dispatch(setActiveModal('vote'))
        sendRetargeting('vote_done')
        sendStatistics('vote_done', {ID_candidate: id})
    }

    useEffect(() => {
        dispatch(setCurrentWomanID(percentagesSorted[0][0]))
        const hash = window.location.hash.slice(1,)

        if(hash && !womanHashUsed){

            dispatch(setWomanHashUsed(true))

            if(hash == 'pokrovskaya'){
                dispatch(setCurrentWomanID(15))
                dispatch(setActivePanel('about'))
            }

        }
    }, []);

    return (
        <div className="container main-page">
            <div className="main-slider main-page__main-slider">
                <div className="main-slider__rail">
                    <Swiper
                        // install Swiper modules
                        modules={[Pagination]}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        onSlideChange={(SwiperSlide) => {
                            setSliderActiveIndex(SwiperSlide.activeIndex)

                        }}
                        onSlideChangeTransitionEnd={()=>{
                            dispatch(setCurrentWomanID(
                                String(
                                    document.querySelector('.swiper-slide-active .main-slide').getAttribute('data-id'))
                                )
                            )
                        }}
                        onInit={(event) => {
                            setSwiperSlider(event)
                        }}
                    >
                        {
                            womenSorted.map((woman, index)=>{
                                return(
                                    <SwiperSlide key={woman.id}>
                                        <div className="main-slide" data-id={woman.id}>
                                            <div className="main-slide__caption-wrapper">
                                                <p className="main-slide__caption" dangerouslySetInnerHTML={{__html: woman.name}}></p>
                                                <p className="main-slide__date" dangerouslySetInnerHTML={{__html: woman.date}}></p>
                                                <p className="main-slide__achievement" dangerouslySetInnerHTML={{__html: woman.achievement}}></p>
                                            </div>
                                            <div className="main-slide-image">
                                                <div className="main-slide-image__woman">
                                                    <img src={`assets/images/women-pics/woman-in-circle${woman.id}.png`} width="280" height="280" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                    <div className="main-slider__pad">
                        <img src="assets/images/woman-light-pad.png" width="432" height="290" alt="" />
                    </div>
                    {/* <div className="main-slider__percent">
                        <PercentsCircleBar percentValue={percentages[currentWomanID]} width="65" height="65"/>
                    </div> */}
                </div>
                <div className="main-slider__buttons">
                    {
                        currentWomanID == 15 ?
                        <Button className={`main-slider__button`} onClick={()=>{
                            dispatch(setActivePanel('about'))
                        }}>
                            <span>Подробнее</span>
                            <img src="assets/images/statue-icon.svg" width="20" height="20" alt="" />
                        </Button>
                        :
                        <button className="sub-button main-slider__sub-button" onClick={()=>{dispatch(setActiveModal('info'))}}>
                            <span>подробнее</span>
                            <img className="sub-button__icon" width="38" height="38" src="assets/images/info-icon.svg" alt="" />
                        </button>
                    }
                </div>
            </div>
            <div className="main-page-nav main-page__main-page-nav">
                {/* <button className="icon-button" onClick={()=>dispatch(setActivePanel('status'))}>
                    <img src="assets/images/smile-icon.svg" alt="" />
                </button> */}
                {/* <button onClick={()=>dispatch(setActivePanel('results'))} style={{color: '#fff'}}>
                    К результатам
                </button> */}
                <div className="main-slider-nav main-page-nav__main-slider-nav">
                    <button className="main-slider-nav__arrow main-slide-prev" onClick={()=>swiperSlider.slidePrev()}>
                        <img src="assets/images/purple-arrow-icon.svg" alt="" />
                    </button>
                    <p className="main-slider-nav__counter">{sliderActiveIndex+1}/{Object.keys(percentages).length}</p>
                    <button className="main-slider-nav__arrow main-slide-next" onClick={()=>swiperSlider.slideNext()}>
                        <img src="assets/images/purple-arrow-icon.svg" alt="" />
                    </button>
                </div>
                {/* <button className="icon-button" onClick={()=>{dispatch(setActiveModal('share'))}}>
                    <img src="assets/images/share-icon.svg" alt="" />
                </button> */}
            </div>
        </div>
    );
}

export default Main;

