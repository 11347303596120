import {createSlice} from "@reduxjs/toolkit";

const mainSlice = createSlice({
    name: 'main',
    initialState: {
        activePanel: 'loading',
        countAllVotes: 1,
        countAllVotes: 1,
        launch_params: {
            // "vk_access_token_settings": "status",
            // "vk_app_id": 51560653,
            // "vk_are_notifications_enabled": 0,
            // "vk_is_app_user": 1,
            // "vk_is_favorite": 0,
            // "vk_language": "ru",
            // "vk_platform": "desktop_web",
            // "vk_ref": "other",
            // "vk_ts": 1677683224,
            // "vk_user_id": 73354123,
            // "sign": "vyXNpuqLjJC6vgAgATVeXpwVHxaG2UKAZ-VzUAKT03g",
            // "vk_is_employee": 1
        },
        history: ['loading'],
        isLoading: false,
        activeModal: null,
        messageModalTitle: "",
        messageModalText: "",
        isModalActive: false,
        statusApp: "poll",
        currentWomanID: 1,
        womanHashUsed: false,
        percentages: {
            "1": "4%",
            "2": "11%",
            "3": "3%",
            "4": "5%",
            "5": "2%",
            "6": "6%",
            "7": "2%",
            "8": "15%",
            "9": "2%",
            "10": "2%",
            "11": "2%",
            "12": "13%",
            "13": "2%",
            "14": "3%",
            "15": "28%",
        },
        userCount: 3,
        isUserNew: true,
        selected: [],
        women: [
            {
                id: 1,
                name: 'Глаголева-Аркадьева<br>Александра Андреевна',
                date: '1884 — 1945',
                adress: 'Флакон, Большая Новодмитровская ул., 36, стр. 2',
                achievement: 'Создала массовый излучатель',
                description: `Изобрела рентгеностереометр — прибор, который измеряет, насколько глубоко пуля и осколок снаряда находятся в ране. Создала новый источник электромагнитных волн — массовый излучатель. Опираясь на предыдущие исследования, она упорядочила единую шкалу электромагнитных волн, которой мы пользуемся до сих пор.`,
            },
            {
                id: 2,
                name: 'Зибер-Шумова<br>Надежда Олимпиевна',
                date: '1856 — 1916',
                adress: 'Флакон, Большая Новодмитровская ул., 36, стр. 2',
                achievement: 'Изобрела вакцину от&nbsp;дифтерии',
                description: `Уехала учиться в Европу, чтобы получить высшее медицинское образование — в Российской империи для женщины это было невозможно. Степень доктора медицины получила в 24 года. Создала вакцину от дифтерии. Учредила первый в истории российской науки грант для поддержки молодых исследователей. Вопреки уставу получила все права действительного члена Императорского института экспериментальной медицины.`
            },
            {
                id: 3,
                name: 'Ладыженская<br>Ольга Александровна',
                date: '1922 — 2004',
                adress: 'Флакон, Большая Новодмитровская ул., 36, стр. 2',
                achievement: 'Определила развитие математической физики и&nbsp;гидродинамики',
                description: `Не сразу удалось поступить в вуз — ей отказали в приёме документов как дочери «врага народа». В 47-м окончила МГУ с отличием. Уже первые результаты Ольги Александровны конца 40-х годов стали прорывом в теории уравнений с частными производными. Её научные труды в области гидродинамики до сих пор используются в современных разработках в кораблестроении, промышленности и медицине. Учила Перельмана, дружила с Солженицыным, Бродским, Ахматовой.`
            },
            {
                id: 4,
                name: 'Преображенская<br>Ольга Ивановна',
                date: '1881 — 1971',
                adress: 'Флакон, Большая Новодмитровская ул., 36, стр. 2',
                achievement: 'Первая женщина в&nbsp;советской режиссуре',
                description: `Фильм «Барышня-крестьянка» 1916 года был первой постановкой женщины-режиссёра. К ленте отнеслись недоверчиво. На афишах и в рецензиях её фамилию писали с мужским окончанием или приписывали постановку другим режиссёрам. Несмотря на это, стала одной из самых успешных режиссёров «Мосфильма» и участвовала в Венецианском кинофестивале.`
            },
            {
                id: 5,
                name: 'Глебова<br>Вера Ильинична',
                date: '1885 — 1935',
                adress: 'Флакон, Большая Новодмитровская ул., 36, стр. 2',
                achievement: 'Создала&nbsp;индустрию&nbsp;добычи редких элементов в&nbsp;СССР',
                description: `Изобрела экономически выгодные промышленные методы получения урана, вольфрама, ванадия, стронция. Открыла радиевый завод и полностью организовала его работу: от оборудования и запуска рудника до набора сотрудников. Позже запустила ещё один завод редких элементов — московский. При этом начинала научную деятельность в микробиологии, работала над вакциной от оспы.`
            },
            {
                id: 6,
                name: 'Данчакова<br>Вера Михайловна',
                date: '1879 — 1950',
                adress: 'Флакон, Большая Новодмитровская ул., 36, стр. 2',
                achievement: '«Мать стволовых клеток»',
                description: `Вопреки воле родителей, вместо музыкального образования выбрала естественные науки, уехала из России и получила медицинскую степень в Лозаннском университете. Исследовала стволовые клетки и популяризировала этот термин, пошатнула прежние теории иммунитета. Обнаружила, что стволовые клетки могут уменьшать рост болезнетворных микроорганизмов и опухолевых клеток.`
            },
            {
                id: 7,
                name: 'Штерн<br>Лина Соломоновна',
                date: '1875 — 1968',
                adress: 'Флакон, Большая Новодмитровская ул., 36, стр. 2',
                achievement: 'Создала концепцию гематоэнцефалического&nbsp;барьера',
                description: `Разработала сыворотки для борьбы с туберкулёзом, энцефалитом и столбняком. Изучала барьер, который защищает мозг от токсинов и микроорганизмов, создала метод борьбы с травматическим шоком. Была членом президиума Еврейского антифашистского комитета. 1949–1952 годы провела в заключении, где написала научный трактат «О раке».`
            },
            {
                id: 8,
                name: 'Руднева-Кашеварова<br>Варвара Александровна',
                date: '1841 — 1899',
                adress: 'Флакон, Большая Новодмитровская ул., 36, стр. 2',
                achievement: 'Одна из&nbsp;первых изучала патологии матки',
                description: `Публиковала работы о женской гигиене — в числе первых научно обосновала её необходимость. Исследовала патологии и онкологию женских половых органов. В 15 лет вышла замуж за купца, а через год оставила его, потому что муж не позволял Варваре учиться. Получила учёную степень доктора медицины. Была вынуждена работать за рубежом — женщину-гинеколога не принимали даже на должность ординатора. Статью о Кашеваровой включили в мировое издание «Женщины-медики». `
            },
            {
                id: 9,
                name: 'Лермонтова<br>Юлия Всеволодовна',
                date: '1847 — 1919',
                adress: 'Флакон, Большая Новодмитровская ул., 36, стр. 2',
                achievement: 'Открыла формулу синтеза&nbsp;углеводородов',
                description: `Вместе с Софьей Ковалевской посещала Гейдельбергский университет на правах вольнослушательницы. Позже Дмитрий Менделеев познакомил её с химиком Александром Бутлеровым. Для синтеза углеводородов до сих пор используется реакция Бутлерова — Эльтекова — Лермонтовой, которую они открыли вместе. Юлия Всеволодовна внесла огромный вклад в развитие промышленности: её исследования способствовали возникновению первых нефтегазовых заводов в России.`
            },
            {
                id: 10,
                name: 'Уварова<br>Прасковья Сергеевна',
                date: '1840 — 1924',
                adress: 'Флакон, Большая Новодмитровская ул., 36, стр. 2',
                achievement: 'Защитница памятников архитектуры',
                description: `Благодаря ей сохранились Успенский собор во Владимире, Успенский собор Московского Кремля, укрепление Китай-города и Смоленский кремль. Стояла у истоков закона по охране культурного наследия. Предложила запретить вывоз из страны исторических памятников и произведений искусства. Опубликовала свыше 170 работ по археологии. Вдохновила Льва Толстого на образ Кити Щербацкой в романе «Анна Каренина». `
            },
            {
                id: 11,
                name: 'Клёнова<br>Мария Васильевна',
                date: '1898 — 1976',
                adress: 'Флакон, Большая Новодмитровская ул., 36, стр. 2',
                achievement: 'Создательница первого антарктического&nbsp;атласа',
                description: `Выпустила первый учебник по геологии моря. Погрузилась на глубину 50 метров на гидростате и дала старт исследованиям с помощью подводных аппаратов. Когда она узнала, что, несмотря на все свои заслуги, не может отправиться в Антарктиду из-за пола, бросила вызов системе и добилась места в экспедиции. Участвовала в экспедициях всю жизнь — последняя была в 74 года.`
            },
            {
                id: 12,
                name: 'Гедройц<br>Вера Игнатьевна',
                date: '1870 — 1932',
                adress: 'Флакон, Большая Новодмитровская ул., 36, стр. 2',
                achievement: 'Хирург с&nbsp;мировым именем',
                description: `Училась в Швейцарии, параллельно работала на нескольких кафедрах, писала доклады и дежурила в клиниках. Получила звание профессора хирургии и возглавила хирургическую кафедру. Во время Русско-японской войны развернула госпиталь в Маньчжурии. Первой начала лечить проникающие ранения прямо на поле боя — в то время эта идея была серьёзным новаторством. Во время учёбы в Лозанне сочиняла стихотворения, позже вошла в состав «Цеха поэтов» Николая Гумилёва.`
            },
            {
                id: 13,
                name: 'Розанова<br>Ольга Владимировна',
                date: '1886 — 1918',
                adress: 'Флакон, Большая Новодмитровская ул., 36, стр. 2',
                achievement: 'Изобрела собственное направление&nbsp;в&nbsp;модернизме',
                description: `Одна из крупнейших представительниц русского авангарда, поэтесса. Её творчество на несколько десятилетий предвосхитило развитие абстрактного искусства. Ольга Розанова прошла через авангард и создала своё направление — цветопись. Создала один из главных шедевров беспредметной живописи XX века — картину «Зелёная полоса». Стала одной из первых, кто применил коллаж как иллюстрацию.`
            },
            {
                id: 14,
                name: 'Шайн<br>Пелагея Фёдоровна',
                date: '1894 — 1956',
                adress: 'Флакон, Большая Новодмитровская ул., 36, стр. 2',
                achievement: 'Открыла 19 малых планет и&nbsp;150 переменных звёзд',
                description: `Начинала как учительница математики в заводской школе. Всю жизнь работала на самом большом в СССР астрономическом рефлекторе. В 1928 году открыла малую планету (1112) Полония, а в 49-м комету Шайн — Шалдеха. В 1955 году Пелагея Фёдоровна тяжело заболела, но всё равно исследовала цвета звёзд в полосе Млечного пути. Перед смертью успела разработать методику для этого исследования. `
            },{
                id: 15,
                name: 'Покровская<br>Магдалина Петровна',
                date: '1901 — 1980',
                adress: '<b>Дизайн завод</b><br>Москва, Большая Новодмитровская&nbsp;ул., 36, стр. 2, 10 вход',
                achievement: 'Создала первую в&nbsp;мире эффективную вакцину против чумы',
                description: `Считала, что учёным нужно «смелее искать новые пути». В разгар эпидемий создала противочумную вакцину и испробовала её на себе. <br><br>Во время Второй мировой войны лечила людей с инфицированными ранами, написала пособие для&nbsp;врачей. Написала больше 100 научных работ и до самой смерти продолжала исследовать инфекционных возбудителей.`
            }
        ]
    },
    reducers: {
        setLaunchParams(state, action) {
            state.launch_params = action.payload
        },
        setActivePanel(state, action) {
            state.activePanel = action.payload
            state.history = [...state.history, action.payload]
            window.history.pushState({panel: state.activePanel}, 'start')
        },
        setActiveModal(state, action) {
            state.activeModal = action.payload
        },
        setMessageModalTitle(state, action){
            state.messageModalTitle = action.payload
        },
        setMessageModalText(state, action){
            state.messageModalText = action.payload
        },
        setIsLoading(state, action){
            state.isLoading = action.payload
        },
        updateHistory(state, action){
            state.history = action.payload
        },
        updateUserCount(state, action){
            state.userCount = action.payload
        },
        setCurrentWomanID(state, action){
            state.currentWomanID = action.payload
        },
        setSelected(state, action){
            state.selected = action.payload
        },
        setStatusApp(state, action){
            state.statusApp = action.payload
        },
        setPercentages(state, action){
            state.percentages = action.payload
        },
        setIsUserNew(state, action){
            state.isUserNew = action.payload
        },
        setCountAllVotes(state, action){
            state.countAllVotes = action.payload
        },
        setWomanHashUsed(state, action){
            state.womanHashUsed = action.payload
        },
    }
})

export default mainSlice.reducer
export const {
    setActivePanel,
    setLaunchParams, 
    setActiveModal, 
    setMessageModalTitle,
    setMessageModalText,
    setIsLoading,
    updateHistory,
    updateUserCount,
    setCurrentWomanID,
    setSelected,
    goBackTo,
    setStatusApp,
    setPercentages,
    setIsUserNew,
    setCountAllVotes,
    setWomanHashUsed
} = mainSlice.actions
