import React,{useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Start from './panels/Start';
import Main from './panels/Main';
import Loading from './panels/Loading';
import Status from './panels/Status';
import Results from './panels/Results';
import Overlay from './panels/Overlay';
import About from './panels/About';
import Button from './components/Button';
import bridge from '@vkontakte/vk-bridge';

import { setActivePanel, setActiveModal, setLaunchParams, setStatusApp, setPercentages, setIsUserNew, setSelected, updateUserCount, setCountAllVotes } from "./store/mainReducer"
import ServerConnect from './service'
import {sendStatistics} from './functions'

import Image1 from '../public/assets/images/info-modal-bg.png'
import Image2 from '../public/assets/images/share-modal-bg.png'
import Image3 from '../public/assets/images/main-bg.jpg'
// import Image4 from '../public/assets/images/loading-bg.jpg'
import Image5 from '../public/assets/images/results-block-bg.png'
import Image6 from '../public/assets/images/status-page-bg.jpg'
import Image7 from '../public/assets/images/info-modal-bg-desktop.png'
import Image8 from '../public/assets/images/loading-bg-desktop.jpg'
import Image9 from '../public/assets/images/main-bg-desktop.jpg'
import Image10 from '../public/assets/images/result-page-desktop-bg.jpg'
import Image11 from '../public/assets/images/roll-block-layer-desktop.png'
import Image12 from '../public/assets/images/roll-block-layer.png'
import Image13 from '../public/assets/images/women-bg-desktop-loop.png'
import Image14 from '../public/assets/images/women-bg-loop.png'

const App = () => {
  const dispatch = useDispatch()
  const launch_params = useSelector(state => state.main.launch_params)
  const activePanel = useSelector(state => state.main.activePanel)
  const activeModal = useSelector(state => state.main.activeModal)
  const userCount = useSelector(state => state.main.userCount)
  const currentWomanID = useSelector(state => state.main.currentWomanID)
  const women = useSelector(state => state.main.women)
  const currentWoman = women.find(woman=>woman.id == currentWomanID)
  const currentWomanName = currentWoman.name
  const currentWomanDescription = currentWoman.description
  const currentWomanAchievement = currentWoman.achievement
  const platform = useSelector(state => state.main.launch_params.vk_platform)

  const preloadSrcList = [
    Image1,
    Image2,
    Image3,
    // Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
    Image11,
    Image12,
    Image13,
    Image14
  ]

  useEffect(() => {

    preloadSrcList.forEach((image) => {
        new Image().src = image
    });

    async function fetchData() {
      const launch_params = await bridge.send("VKWebAppGetLaunchParams");
      if (launch_params) {
        return launch_params;
      }
    }

    fetchData().then(data => {

      dispatch(setLaunchParams(data))
      ServerConnect.sendGet('/get_data', {...data}).then(data => {
        dispatch(setStatusApp(data.statusApp))
        if(data.percentages.custom){
          dispatch(setPercentages(data.percentages.custom))
        } else {
          dispatch(setPercentages(data.percentages))
        }
        dispatch(updateUserCount(data.user_count))
        dispatch(setIsUserNew(data.is_user_new))
        dispatch(setSelected(data.selected))
        dispatch(setCountAllVotes(data.count_all_votes))

        if(platform === 'desktop_web'){
          bridge.send('VKWebAppResizeWindow', {
            height: 670
          })
          .catch((error) => {
            console.log(error);
          });
        }
      })

    })

    bridge.send("VKWebAppSetViewSettings", {
      "status_bar_style": "light",
      "action_bar_color": "#000139"
    });

    bridge.subscribe(event => {
      if (!event.detail) { return; }
      const { type, data } = event.detail;
      // if (type === 'VKWebAppShowStoryBoxLoadFinish') {
      // ServerConnect.sendGet('/storied', {...launch_params})
      // }

      if (type === 'VKWebAppShowStoryBoxLoadFinish') {
        sendStatistics('share_done', {share_type: "story"})
      }

      if (type === 'VKWebAppShowWallPostBoxResult') {
        sendStatistics('share_done', {share_type: "wall"})
      }

    });

  }, [])

  useEffect(()=>{
    if(platform === 'desktop_web' && activePanel !== 'about'){
        bridge.send('VKWebAppResizeWindow', {
          height: activePanel === 'results' ? 670 : 670
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }, [activePanel])

  function storyHandler(storyType) {

    let storyImageUrl = `https://vk-women.gamesmm.ru/assets/images/storyshare-pic.jpg`

    if(storyType === 'personal'){
      storyImageUrl = `https://vk-women.gamesmm.ru/assets/images/story-pics/woman-storypic${currentWomanID}.jpg`
    }

    bridge.send("VKWebAppShowStoryBox", {
      "background_type" : "image",
      "url": storyImageUrl,
      "attachment": {
          "text": "open",
          "type": "url",
          "url": "https://vk.com/app51560653"
    }})
    .then(data=>{
      console.log('story')
    })
    .catch(err=>console.log(err));
  }

  function shareHandler(shareType)  {

    // let shareImageUrl = 'https://vk-women.gamesmm.ru/assets/images/wallshare-pic.jpg'

    if(shareType === 'personal'){
      shareImageUrl = `https://vk-women.gamesmm.ru/assets/images/share-pics/woman-sharepic${currentWomanID}.jpg`
    }

    let params = {
        "owner_id": launch_params.vk_user_id,
        "message": 'Вы знали, что в России лишь 2% памятников посвящены женщинам? Теперь постаментов стало на один больше — узнайте, кому он посвящён и где его найти.',
        "attachments": "https://vk.com/app51560653",
        "link_button": 'open_url',
        // "link_image": shareImageUrl,
    }

    bridge.send("VKWebAppShowWallPostBox", params)
    .then(data=>{
      console.log('share')
    })
    .catch(err=>console.log(err));
  }

  const InfoModal = ()=> {
    return (
      <div className="modal info-modal" onClick={(e)=>{e.stopPropagation();}}>
        <div className="info-modal-wrapper">
          <h3 className="info-modal-wrapper__caption caption" dangerouslySetInnerHTML={{__html: currentWomanName}}></h3>
          <p className="info-modal-wrapper__achievement" dangerouslySetInnerHTML={{__html: currentWomanAchievement}}></p>
          <p className="info-modal-wrapper__description" dangerouslySetInnerHTML={{__html: currentWomanDescription}}></p>
          <button className="info-modal-wrapper__close modal__close" onClick={()=>dispatch(setActiveModal(null))}>
            <img src="assets/images/cross-icon.svg"/>
          </button>
        </div>
      </div>
    )
  }

  const VoteModal = ()=> {
    return (
      <div className="modal vote-modal" onClick={(e)=>{e.stopPropagation();}}>
        <div className="vote-modal-wrapper">
          <p className="vote-modal__counter">{3-userCount}/3</p>
          <h3 className="vote-modal-wrapper__caption caption">Ваш голос учтён</h3>
          <p className="vote-modal-wrapper__text text">
          Спасибо за&nbsp;поддержку. Напомним, каждый день вы&nbsp;можете проголосовать за&nbsp;трёх женщин.
          <br/><br/>Поделитесь проектом, чтобы о&nbsp;выдающихся женщинах знали больше.
          </p>
          <Button className="vote-modal__button" onClick={()=>{
            dispatch(setActiveModal('personal-share'))
          }}>
            <span>Поделиться</span>
            <img src="assets/images/share-icon.svg" width="20" height="20" alt="" />
          </Button>
          <button className="vote-modal-wrapper__close modal__close" onClick={()=>dispatch(setActiveModal(null))}>
            <img src="assets/images/cross-icon.svg"/>
          </button>
        </div>
      </div>
    )
  }

  const ShareModal = ()=> {
    return (
      <div className="modal share-modal" onClick={(e)=>{e.stopPropagation();}}>
        <div className="share-modal-wrapper">
          <h3 className="share-modal-wrapper__caption caption">Расскажите друзьям о&nbsp;проекте</h3>
          <Button className="share-modal__button share-modal__first-button" onClick={shareHandler}>
            На стене
          </Button>
          <Button className="share-modal__button" onClick={storyHandler}>
            В историю
          </Button>
          <button className="share-modal-wrapper__close modal__close" onClick={()=>dispatch(setActiveModal(null))}>
            <img src="assets/images/cross-icon.svg"/>
          </button>
        </div>
      </div>
    )
  }

  const PersonalShareModal = ()=> {
    return (
      <div className="modal share-modal" onClick={(e)=>{e.stopPropagation();}}>
        <div className="share-modal-wrapper">
          <h3 className="share-modal-wrapper__caption caption">Расскажите друзьям о&nbsp;проекте</h3>
          <Button className="share-modal__button share-modal__first-button" onClick={()=>shareHandler('personal')}>
            На стене
          </Button>
          <Button className="share-modal__button" onClick={()=>storyHandler('personal')}>
            В историю
          </Button>
          <button className="share-modal-wrapper__close modal__close" onClick={()=>dispatch(setActiveModal(null))}>
            <img src="assets/images/cross-icon.svg"/>
          </button>
        </div>
      </div>
    )
  }

  let content = []
  if (activePanel === 'loading') {
    content.push(<Loading key='loading'/>)
  } else if (activePanel === 'start') {
    content.push(<Start key='start'/>)
  } else if (activePanel === 'main') {
    content.push(<Main key='main'/>)
  } else if (activePanel === 'results') {
    content.push(<Results key='results'/>)
  } else if (activePanel === 'status') {
    content.push(<Status key='status'/>)
  } else if (activePanel === 'about') {
    content.push(<About key='about'/>)
  }

  return (
    <div className='app'>
      <>
        {content}
        <Overlay>
          {
            activeModal === 'info' ? <InfoModal /> :
            activeModal === 'vote' ? <VoteModal /> :
            activeModal === 'personal-share' ? <PersonalShareModal /> :
            activeModal === 'share' && <ShareModal />
          }
        </Overlay>
      </>
    </div>
  );
}
export default App;
